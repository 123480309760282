// Sequel Sans in various weights, pulled from DAT server

// light text
@font-face {
    font-family: "Sequel Sans";
    font-weight: 300;
    src: url(https://shared-content.prod.dat.com/fonts/sequel-sans-light-headline.woff2) format('woff2'),
    url(https://shared-content.prod.dat.com/fonts/sequel-sans-light-headline.woff) format('woff'),
    url(https://shared-content.prod.dat.com/fonts/sequel-sans-light-headline.ttf) format('truetype');
}

// body text
@font-face {
    font-family: "Sequel Sans";
    font-weight: 400;
    src: url(https://shared-content.prod.dat.com/fonts/sequel-sans-book-body-text.woff2) format('woff2'),
        url(https://shared-content.prod.dat.com/fonts/sequel-sans-book-body-text.woff) format('woff'),
        url(https://shared-content.prod.dat.com/fonts/sequel-sans-book-body-text.ttf) format('truetype');
}

// medium text
@font-face {
    font-family: "Sequel Sans";
    font-weight: 500;
    src: url(https://shared-content.prod.dat.com/fonts/sequel-sans-medium-headline.woff2) format('woff2'),
        url(https://shared-content.prod.dat.com/fonts/sequel-sans-medium-headline.woff) format('woff2'),
        url(https://shared-content.prod.dat.com/fonts/sequel-sans-medium-headline.ttf) format('truetype');
}

// bold text
@font-face {
    font-family: "Sequel Sans";
    font-weight: 700;
    src: url(https://shared-content.prod.dat.com/fonts/sequel-sans-bold-body-text.woff2) format('woff2'),
        url(https://shared-content.prod.dat.com/fonts/sequel-sans-bold-body-text.woff) format('woff'),
        url(https://shared-content.prod.dat.com/fonts/sequel-sans-bold-body-text.ttf) format('truetype');
}

// extra bold text, used for heavier headlines
@font-face {
    font-family: "Sequel Sans";
    font-weight: 800;
    src: url(https://shared-content.prod.dat.com/fonts/sequel-sans-bold-headline.woff2) format('woff2'),
        url(https://shared-content.prod.dat.com/fonts/sequel-sans-bold-headline.woff) format('woff'),
        url(https://shared-content.prod.dat.com/fonts/sequel-sans-bold-headline.ttf) format('truetype');
}

// ultra bold text, like headlines and app name
@font-face {
    font-family: "Sequel Sans";
    font-style: bold;
    font-weight: 900;
    src: url(https://shared-content.prod.dat.com/fonts/sequel-sans-black-headline.woff2) format('woff2'),
        url(https://shared-content.prod.dat.com/fonts/sequel-sans-black-headline.woff) format('woff'),
        url(https://shared-content.prod.dat.com/fonts/sequel-sans-black-headline.ttf) format('truetype');
}

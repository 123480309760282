@import './dat-colors';
@import './sequel-sans.font';
@import './dat-responsive';

$datiq-font-family: Sequel Sans, Helvetica, Arial, Sans-Serif;
$color: get-dat-color(grey, 900);
$color-wash: get-dat-color(grey, 500);
$color-lighter: #9ca5ae;
$normal-letter-spacing: 0px;

@mixin datFontFamily() {
    font-family: $datiq-font-family;
}

@mixin bold() {
    font-weight: 600;
}
@mixin textColorLight() {
    color: $color-wash;
}

@mixin datBodyFontTypography() {
    @include datFontFamily;
    $min-font-size: 14px;
    $max-font-size: 14px;
    $min-line-height: 20px;
    $max-line-height: 20px;
    @include fluid-type(
        $min-font-size,
        $max-font-size,
        $min-line-height,
        $max-line-height,
        $normal-letter-spacing,
        $normal-letter-spacing
    );
}

@mixin datSubtitleFontTypography() {
    @include datFontFamily;
    $min-font-size: 16px;
    $max-font-size: 16px;
    $min-line-height: 22px;
    $max-line-height: 22px;
    @include fluid-type(
        $min-font-size,
        $max-font-size,
        $min-line-height,
        $max-line-height,
        $normal-letter-spacing,
        $normal-letter-spacing
    );
}

@mixin datSubtitle2FontTypography() {
    @include datFontFamily;
    $min-font-size: 14px;
    $max-font-size: 14px;
    $min-line-height: 18px;
    $max-line-height: 18px;
    @include fluid-type(
        $min-font-size,
        $max-font-size,
        $min-line-height,
        $max-line-height,
        $normal-letter-spacing,
        $normal-letter-spacing
    );
}

@mixin datOverlineFontTypography() {
    @include datFontFamily;
    $min-font-size: 10px;
    $max-font-size: 10px;
    $min-line-height: 16px;
    $max-line-height: 16px;
    text-transform: uppercase;
    @include fluid-type(
        $min-font-size,
        $max-font-size,
        $min-line-height,
        $max-line-height,
        1.5px,
        1.5px
    );
}

@mixin datCaptionFontTypography() {
    @include datFontFamily;
    $min-font-size: 12px;
    $max-font-size: 12px;
    $min-line-height: 16px;
    $max-line-height: 16px;
    @include fluid-type(
        $min-font-size,
        $max-font-size,
        $min-line-height,
        $max-line-height,
        0.2px,
        $normal-letter-spacing
    );
}

@mixin datCaptionFontMicroTypography() {
    @include datFontFamily;
    $min-font-size: 10px;
    $min-line-height: 14px;
    $micro-letter-spacing: 0.2px;
    @include fluid-type(
        $min-font-size,
        $min-font-size,
        $min-line-height,
        $min-line-height,
        $micro-letter-spacing,
        $micro-letter-spacing
    );
}

@mixin datButtonFontTypography() {
    @include datFontFamily;
    $min-font-size: 12px;
    $max-font-size: 12px;
    $min-line-height: 14px;
    $max-line-height: 14px;
    font-weight: 500; // according to Figma!
    text-transform: uppercase;
    @include fluid-type(
        $min-font-size,
        $max-font-size,
        $min-line-height,
        $max-line-height,
        $normal-letter-spacing,
        1px
    );
}

@mixin datH1FontTypography() {
    @include datFontFamily;
    $min-font-size: 72px;
    $max-font-size: 72px;
    $min-line-height: 96px;
    $max-line-height: 96px;
    @include fluid-type(
        $min-font-size,
        $max-font-size,
        $min-line-height,
        $max-line-height,
        $normal-letter-spacing,
        $normal-letter-spacing
    );
}

@mixin datH2FontTypography() {
    @include datFontFamily;
    $min-font-size: 60px;
    $max-font-size: 60px;
    $min-line-height: 72px;
    $max-line-height: 72px;
    @include fluid-type(
        $min-font-size,
        $max-font-size,
        $min-line-height,
        $max-line-height,
        $normal-letter-spacing,
        $normal-letter-spacing
    );
}

@mixin datH3FontTypography() {
    @include datFontFamily;
    $min-font-size: 48px;
    $max-font-size: 48px;
    $min-line-height: 56px;
    $max-line-height: 56px;
    @include fluid-type(
        $min-font-size,
        $max-font-size,
        $min-line-height,
        $max-line-height,
        $normal-letter-spacing,
        $normal-letter-spacing
    );
}

@mixin datH4FontTypography() {
    @include datFontFamily;
    $min-font-size: 34px;
    $max-font-size: 34px;
    $min-line-height: 36px;
    $max-line-height: 36px;
    @include fluid-type(
        $min-font-size,
        $max-font-size,
        $min-line-height,
        $max-line-height,
        $normal-letter-spacing,
        $normal-letter-spacing
    );
}

@mixin datH5FontTypography() {
    @include datFontFamily;
    $min-font-size: 24px;
    $max-font-size: 24px;
    $min-line-height: 28px;
    $max-line-height: 28px;
    @include fluid-type(
        $min-font-size,
        $max-font-size,
        $min-line-height,
        $max-line-height,
        $normal-letter-spacing,
        $normal-letter-spacing
    );
}

@mixin datH6FontTypography() {
    @include datFontFamily;
    $min-font-size: 20px;
    $max-font-size: 20px;
    $min-line-height: 24px;
    $max-line-height: 24px;
    @include fluid-type(
        $min-font-size,
        $max-font-size,
        $min-line-height,
        $max-line-height,
        $normal-letter-spacing,
        $normal-letter-spacing
    );
}

@mixin datBodyFontRegular() {
    @include datBodyFontTypography();
    @include datRegular();
}

@mixin datBodyFontStrong() {
    @include datBodyFontTypography();
    @include datStrong();
}

@mixin datBodyFontWash() {
    @include datBodyFontTypography();
    @include datLight();
}
@mixin datSubtitleFontRegular() {
    @include datSubtitleFontTypography();
    @include datRegular();
    font-weight: 500; // according to Figma!
}
@mixin datSubtitle2FontRegular() {
    @include datSubtitle2FontTypography();
    @include datRegular();
    font-weight: 500; // according to Figma!
}

@mixin datSubtitleFontStrong() {
    @include datSubtitleFontTypography();
    @include datStrong();
}

@mixin datSubtitleFontWash() {
    @include datSubtitleFontTypography();
    @include datLight();
}

@mixin datOverlineFontRegular() {
    @include datOverlineFontTypography();
    @include datRegular();
    font-weight: 500; // according to Figma!
}

@mixin datOverlineFontStrong() {
    @include datOverlineFontTypography();
    @include datStrong();
}

@mixin datOverlineFontWash() {
    @include datOverlineFontTypography();
    @include datLight();
    font-weight: 500; // according to Figma!
}

@mixin datCaptionFontRegular() {
    @include datCaptionFontTypography();
    @include datRegular();
}

@mixin datCaptionFontStrong() {
    @include datCaptionFontTypography();
    @include datStrong();
}

@mixin datCaptionMicroRegular() {
    @include datCaptionFontMicroTypography();
    @include datRegular();
}

@mixin datCaptionMicroWash() {
    @include datCaptionFontMicroTypography();
    @include datLight();
}

@mixin datCaptionMicroStrong() {
    @include datCaptionFontMicroTypography();
    @include datStrong();
}

@mixin datCaptionFontWash() {
    @include datCaptionFontTypography();
    @include datLight();
}

@mixin datButtonFontRegular() {
    @include datButtonFontTypography();
    @include datRegular();
    font-weight: 500; // according to Figma!
}

@mixin datH1FontRegular() {
    @include datH1FontTypography();
    @include datRegular();
}

@mixin datH1FontStrong() {
    @include datH1FontTypography();
    @include datBlack();
}

@mixin datH1FontWash() {
    @include datH1FontTypography();
    @include datLight();
}

@mixin datH2FontRegular() {
    @include datH2FontTypography();
    @include datRegular();
}

@mixin datH2FontStrong() {
    @include datH2FontTypography();
    @include datBlack();
}

@mixin datH2FontWash() {
    @include datH2FontTypography();
    @include datLight();
}

@mixin datH3FontRegular() {
    @include datH3FontTypography();
    @include datRegular();
}

@mixin datH3FontStrong() {
    @include datH3FontTypography();
    @include datBlack();
}

@mixin datH3FontWash() {
    @include datH3FontTypography();
    @include datLight();
}

@mixin datH4FontRegular() {
    @include datH4FontTypography();
    @include datRegular();
}

@mixin datH4FontStrong() {
    @include datH4FontTypography();
    @include datBlack();
}

@mixin datH4FontWash() {
    @include datH4FontTypography();
    @include datLight();
}

@mixin datH5FontRegular() {
    @include datH5FontTypography();
    @include datRegular();
}

@mixin datH5FontStrong() {
    @include datH5FontTypography();
    @include datBlack();
}

@mixin datH5FontWash() {
    @include datH5FontTypography();
    @include datLight();
}

@mixin datH6FontRegular() {
    @include datH6FontTypography();
    @include datRegular();
}

@mixin datH6FontStrong() {
    @include datH6FontTypography();
    @include datBlack();
}

@mixin datH6FontWash() {
    @include datH6FontTypography();
    @include datLight();
}

@mixin emphasis() {
    line-height: 1.17;
    letter-spacing: 1px;
    font-size: 12px;
    font-weight: 600;
}

@mixin mediumEmphasis() {
    @include emphasis();
    text-transform: uppercase;
}

@mixin datLight() {
    font-weight: normal;
    color: $color-wash;
}
@mixin datRegular() {
    font-weight: normal;
    color: $color;
}

$dat-font-weight-strong: 700;
@mixin datStrongWeight() {
    font-weight: $dat-font-weight-strong;
}
@mixin datStrong() {
    @include datStrongWeight();
    color: $color;
}

@mixin datItalics() {
    font-style: italic;
}

@mixin datBlack() {
    font-weight: 900;
    color: get-dat-color(grey, 900);
}

@mixin datHeaderBlack() {
    @include datBlack();
}

@mixin datTitle() {
    @include datSubtitleFontStrong();
    text-transform: uppercase;
}
@mixin datiqMdTemplateOverrides() {
    ::ng-deep {
        p {
            margin: 0;
        }
    }
}

@import './dat-colors';

@mixin datiqScrollbar() {
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    ::-webkit-scrollbar-track {
        background: get-dat-color(dat, bg);
    }

    ::-webkit-scrollbar-thumb {
        background: get-dat-transparent-color(dat, dark, light);
    }

    ::-webkit-scrollbar-thumb:hover {
        background: get-dat-transparent-color(dat, dark);
    }
}

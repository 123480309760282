@import 'material-theme';
@import 'DAT';
@import 'dat-colors';
@import 'dat-typography';
@import 'dat-variables';
@import 'dat-shared';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    *, ::before, ::after {
        border-style: initial;
    }
}
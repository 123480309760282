@mixin datiqAutocomplete() {
    .mat-autocomplete-panel {
        &.datiq-autocomplete {
            &__autocomplete {
                min-width: 210px;
            }
        }
    }
}

@mixin datiqMat15RadioButtonsAutocompleteOverrideButtonColor() {
    .mdc-radio {
        &__native-control {
            &:enabled {
                &+.mdc-radio {
                    &__background {
                        .mdc-radio {

                            &__inner-circle,
                            &__outer-circle {
                                border-color: black;
                            }
                        }
                    }
                }
            }
        }
    }
}

@mixin datiqMat15RadioButtonsAutocompleteOverride() {
    display: block;

    ::ng-deep {
        .mdc-list-item {
            &__primary-text {
                flex-grow: 1;
            }
        }

        .mdc-form-field {
            width: 100%;

            label {
                flex-grow: 1;
            }
        }

        .mat-mdc-option {
            &.mdc-list-item {
                &--disabled {
                    opacity: 1;
                }
            }

            .mat-mdc-radio-button {
                .mdc-radio {
                    @include datiqMat15RadioButtonsAutocompleteOverrideButtonColor();

                    &:hover {
                        @include datiqMat15RadioButtonsAutocompleteOverrideButtonColor();
                    }
                }
            }
        }

        .datiq-autocomplete {
            &__autocomplete {
                &__option {
                    &__radio {
                        display: block;
                    }
                }
            }
        }

    }
}
@use "sass:math";

@mixin breakpoint($class) {
    @if $class == xs {
        @media (max-width: 767px) {
            @content;
        }
    } @else if $class == sm {
        @media (min-width: 768px) {
            @content;
        }
    } @else if $class == md {
        @media (min-width: 992px) {
            @content;
        }
    } @else if $class == lg {
        @media (min-width: 1200px) {
            @content;
        }
    } @else if $class == xl {
        @media (min-width: 2250px) {
            @content;
        }
    } @else {
        @warn "Breakpoint mixin supports: xs, sm, md, lg, xl";
    }
}

@mixin hideOnDesktop() {
    display: inherit;

    @include breakpoint(lg) {
        display: none;
    }
}
@mixin hideOnTablet() {
    display: none;

    @include breakpoint(lg) {
        display: inherit;
    }
}

@function strip-unit($value) {
    @return math.div($value, ($value * 0 + 1));
}

@mixin fluid-type(
    $min-font-size,
    $max-font-size,
    $min-line-height,
    $max-line-height,
    $min-letter-spacing,
    $max-letter-spacing
) {
    $min-vw: 768px;
    $max-vw: 1200px;

    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);
    $min-space: unit($min-letter-spacing);
    $max-space: unit($max-letter-spacing);

    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
        & {
            font-size: $min-font-size;
            line-height: $min-line-height;

            letter-spacing: $min-letter-spacing;

            @media screen and (min-width: $min-vw) {
                font-size: calc(
                    #{$min-font-size} + #{strip-unit(
                            $max-font-size - $min-font-size
                        )} *
                        (
                            (100vw - #{$min-vw}) / #{strip-unit(
                                    $max-vw - $min-vw
                                )}
                        )
                );
                line-height: calc(
                    #{$min-line-height} + #{strip-unit(
                            $max-line-height - $min-line-height
                        )} *
                        (
                            (100vw - #{$min-vw}) / #{strip-unit(
                                    $max-vw - $min-vw
                                )}
                        )
                );
                @if $min-space != $max-space {
                    letter-spacing: calc(
                        #{$min-letter-spacing} +
                            #{strip-unit(
                                $max-letter-spacing - $min-letter-spacing
                            )} *
                            (
                                (100vw - #{$min-vw}) /
                                    #{strip-unit($max-vw - $min-vw)}
                            )
                    );
                } @else {
                    letter-spacing: $min-letter-spacing;
                }
            }
            @media screen and (min-width: $max-vw) {
                font-size: $max-font-size;
                line-height: $max-line-height;
                letter-spacing: $max-letter-spacing;
            }
        }
    }
}

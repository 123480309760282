@import './dat-colors.scss';
@import './dat-typography.scss';

@mixin datiqInputErrorMessage() {
    @include datSubtitleFontRegular();
    color: get-dat-color(dat, error);
}

@mixin datiqInputSelectPanel() {
    .datiq-select-pill__panel {
        margin-top: 20px;
    }
}

@mixin datiqInputBaSelectPanel() {
    .datiq-ba-select__panel {
        margin-top: 25px;
    }
}

@mixin datiqInputMatFormOutlineBorder() {
    border-width: 1px;
}

@mixin datiqInputMatFormOutlineBorderThick() {
    border-width: 0px;
}

@mixin datiqInputMatFormOutline() {
    $color: get-dat-color(grey, 1000);

    color: $color;
}
@mixin datiqInputMatFormOutlineFocused() {
    $color: get-dat-color(blue, 500);

    color: $color;
}

@mixin datiqInputHeaderTitle() {
    @include datOverlineFontRegular();
    color: get-dat-color(grey, 900);
}

@mixin datiqInputHeader() {
    background: get-dat-color(grey, 50);
    padding: 10px 16px;
}

@mixin datiqInputMatFormOutlineOverride() {
    ::ng-deep {
        .mat-form-field {
            &-outline {
                @include datiqInputMatFormOutline();

                &-start,
                &-end {
                    @include datiqInputMatFormOutlineBorder();
                }

                &-thick {
                    @include datiqInputMatFormOutlineBorderThick();
                }
            }
        }
    }

    &.mat-focused {
        ::ng-deep {
            .mat-form-field-outline-thick {
                @include datiqInputMatFormOutlineFocused();
            }
        }
    }
}

@mixin datiqDatepickerInputOverride {
    ::ng-deep {
        .mat-form-field {
            @include datBodyFontRegular();
            width: 100%;
        }

        .mat-form-field-infix {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .mat-form-field-appearance-outline .mat-form-field-infix {
            padding: 0em 0em;
            border: 0;
            margin: 8px 0px;
        }

        .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
            padding: 0em;
        }

        .mat-form-field-invalid .mat-form-field-wrapper {
            padding-bottom: 1em;
        }
    }
}

@mixin datiqInputOutlineOverride() {
    ::ng-deep {
        .mat-form-field-outline {
            color: get-dat-color(grey, 1000);
        }
    }
}

@mixin matWrapperPadding() {
    padding-bottom: 1.34375em;
}

@mixin datiqInputMatFormNoLabel() {
    padding: 0.7em 0em 0.5em 0em;
    border: 0;
}

@mixin datiqInputPanelVisibleOverride() {
    ::ng-deep {
        .mat-autocomplete-panel.mat-autocomplete-visible {
            margin-top: 8px;
        }

        .mat-autocomplete-panel {
            background: inherit;
        }
    }
}

@mixin datiqInput() {
    @include datBodyFontRegular();
    height: 38px;
}

@mixin datiqInputDisabled() {
    opacity: 0.4;
}

@mixin datiqFormFieldDisabled() {
    opacity: 0.25;
}

@mixin datiqChipMatOverrides() {
    --mdc-chip-elevated-container-color: #{get-dat-transparent-color(blue, 100)};
}

@mixin datiqChip() {
    white-space: nowrap;
    border-radius: 4px;
    background-color: get-dat-transparent-color(blue, 100);
    padding: 2px 8px;
    color: get-dat-color(dat, black);
    @include datLight();
    @include datiqChipMatOverrides();
}

@mixin datiqChipIcon() {
    color: get-dat-color(dat, black);
}

@mixin datiqOption() {
    color: get-dat-color(dat, black);
    background: get-dat-color(dat, white);

    &:hover {
        background: get-dat-color(grey, 050);
    }
}

@mixin datiqInputDatepickerCalendar() {
    .datiq-datepicker__calendar {
        .mat-calendar-body-selected {
            background-color: get-dat-color(blue, 500);
        }

        .mat-calendar-body-in-range {
            &::before {
                background-color: get-dat-color(blue, 50);
            }
        }
    }
}

@mixin datiqCheckboxColorOverride() {
    ::ng-deep {
        .mat-checkbox-checked:not(.mat-disabled) .mat-checkbox-background {
            background-color: get-dat-color(dat, blue);
        }
    }

    ::ng-deep {
        .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,
        .mat-checkbox-disabled.mat-checkbox-indeterminate
            .mat-checkbox-background {
            background-color: get-dat-color(blue, 100);
        }
    }

    ::ng-deep
        .mat-checkbox-checked.mat-accent
        .mat-checkbox-ripple
        .mat-ripple-element {
        background-color: get-dat-color(blue, 100) !important;
    }
}

@mixin datiqSwitchColorOverride(
    $bgColor: get-dat-color(blue, 100),
    $toggleColor: get-dat-color(blue, 500)
) {
    ::ng-deep {
        .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
            background-color: $bgColor;
        }
    }

    ::ng-deep {
        .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
            background-color: $toggleColor;
        }
    }

    ::ng-deep {
        .mat-slide-toggle.mat-checked
            .mat-slide-toggle-ripple
            .mat-ripple-element {
            background-color: $bgColor;
        }
    }

    ::ng-deep {
        .mat-slide-toggle .mat-slide-toggle-bar {
            background-color: get-dat-color(grey, 200);
        }
    }

    ::ng-deep {
        .mat-slide-toggle .mat-slide-toggle-thumb {
            background-color: get-dat-color(grey, 900);
        }
    }
}

@mixin datInputFormFieldSuffixButtonOverride() {
    ::ng-deep {
        .mat-form-field:not(.mat-form-field-appearance-legacy)
            .mat-form-field-prefix
            .mat-icon,
        .mat-form-field:not(.mat-form-field-appearance-legacy)
            .mat-form-field-suffix
            .mat-icon {
            display: none;
        }
    }
}

::ng-deep {
    .mat-form-field-wrapper {
        padding-bottom: 0em;
    }

    .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
        padding: 0em;
    }
}

::ng-deep {
    .mat-checkbox-ripple .mat-ripple-element,
    .mat-checkbox-checked.mat-accent .mat-checkbox-background {
        background-color: get-dat-color(dat, blue);
    }

    .mat-radio-button.mat-accent .mat-radio-inner-circle {
        color: get-dat-color(dat, blue);
        background-color: get-dat-color(dat, blue);
    }

    .mat-mdc-radio-button
        .mdc-radio
        .mdc-radio__background
        .mdc-radio__inner-circle {
        border-color: get-dat-color(dat, blue) !important;
    }

    .mat-mdc-radio-button
        .mdc-radio
        .mdc-radio__background
        .mdc-radio__outer-circle {
        border-color: get-dat-color(dat, blue) !important;
    }

    .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
        background-color: get-dat-color(dat, blue);
    }
    .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
        border-color: get-dat-color(dat, blue); /*outer ring color change*/
    }

    .mat-mdc-form-field-flex {
        height: 52px;
    }

    .mat-mdc-text-field-wrapper.mdc-text-field--outlined
        .mat-mdc-form-field-infix {
        padding-top: 8px;
        padding: 0px;
        display: flex;
        align-items: center;
    }

    .mat-mdc-text-field-wrapper
        .mat-mdc-form-field-flex
        .mat-mdc-floating-label {
        top: 24px;
    }

    .mat-mdc-form-field {
        display: flex !important;
    }

    .cdk-overlay-pane:not(.mat-mdc-select-panel-above)
        .mdc-menu-surface.mat-mdc-select-panel {
        margin-top: 0px !important;
    }

    .mat-mdc-unelevated-button > .mat-icon {
        height: 24px;
        width: 24px;
    }

    .mat-mdc-checkbox
        .mdc-checkbox
        .mdc-checkbox__native-control:enabled:indeterminate
        ~ .mdc-checkbox__background {
        border-color: get-dat-color(dat, blue) !important;
        background-color: get-dat-color(dat, blue) !important;
    }

    .mat-mdc-checkbox
        .mdc-checkbox
        .mdc-checkbox__native-control:enabled:checked
        ~ .mdc-checkbox__background {
        border-color: get-dat-color(dat, blue) !important;
        background-color: get-dat-color(dat, blue) !important;
    }
}

@mixin datiqMenuBoxShadow() {
    box-shadow: 0px 2px 12px -1px rgba(0, 0, 0, 0.05),
        0px 4px 14px 0px rgba(0, 0, 0, 0.05), 0px 1px 12px 0px rgba(0, 0, 0, 0.05);
}

@mixin datiqRfpStatusMenu() {
    margin-top: 4px;
}

@mixin datiqRfpStatusMenuContent() {
    padding: 16px 0;
}

@mixin datiqRfpStatusMenuItem() {
    height: 34px;
    padding: 0 20px;
}

@mixin datiqMenuMaterialOverrides() {
    .mat-menu-panel {
        @include datiqMenuBoxShadow();
        margin-top: 4px;
    }
}

@mixin datiqMat15MenuTriggerButtonOverride() {
    ::ng-deep {
        .mdc-button {
            &__label {
                display: flex;
                align-items: center;
            }
        }
    }
}
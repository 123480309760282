@import "../../../../../node_modules/@angular/material/theming";
@import "./dat-typography.scss";
// Plus imports for other components in your app.

$dat-primary-palette: (
    50: #ffffff,
    100: #8fadff,
    200: #759aff,
    300: #03a9f4,
    400: #0091ea,
    500: #0091ea,
    600: #0373b5,
    700: #0277bd,
    800: #025b8e,
    900: #333333,
    A100: #80d8ff,
    A200: #dbe5ff,
    A400: #f5f8ff,
    A700: #0289f7,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: white,
        600: white,
        700: white,
        800: white,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $dark-primary-text,
        A400: $dark-primary-text,
        A700: white,
    ),
);

$dat-accent-palette: (
    50: #ffffff,
    100: #002da8,
    200: #0034c2,
    300: #003adb,
    400: #0041f5,
    500: #0f4fff,
    600: #4275ff,
    700: #5c87ff,
    800: #759aff,
    900: #8fadff,
    A100: #0091ea,
    A200: #0091ea,
    A400: #0091ea,
    A700: #a8c0ff,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: white,
        600: white,
        700: white,
        800: white,
        900: $light-primary-text,
        A100: white,
        A200: white,
        A400: $dark-primary-text,
        A700: white,
    ),
);

$dat-dark-primary-palette: (
    50: black,
    100: black,
    200: #212121,
    300: #212121,
    400: $dark-secondary-text,
    500: $dark-secondary-text,
    600: #424242,
    700: #212121,
    800: #757575,
    900: #9e9e9e,
    A100: #64c1ff,
    A200: #64c1ff,
    A400: #64c1ff,
    A700: #a8c0ff,
    contrast: (
        50: #64c1ff,
        100: #64c1ff,
        200: #64c1ff,
        300: #64c1ff,
        400: #64c1ff,
        500: #ffffff,
        600: #64c1ff,
        700: #64c1ff,
        800: #64c1ff,
        900: #64c1ff,
        A100: #64c1ff,
        A200: #64c1ff,
        A400: #64c1ff,
        A700: #64c1ff,
    ),
);

$dat-dark-accent-palette: (
    50: #64c1ff,
    100: #64c1ff,
    200: #64c1ff,
    300: #64c1ff,
    400: #64c1ff,
    500: #ffffff,
    600: #64c1ff,
    700: #64c1ff,
    800: #64c1ff,
    900: #64c1ff,
    A100: #64c1ff,
    A200: #64c1ff,
    A400: #64c1ff,
    A700: #64c1ff,
    contrast: (
        50: #ffffff,
        100: black,
        200: #212121,
        300: #212121,
        400: $dark-secondary-text,
        500: $dark-secondary-text,
        600: #424242,
        700: #212121,
        800: #757575,
        900: #9e9e9e,
        A100: #64c1ff,
        A200: $light-secondary-text,
        A400: #64c1ff,
        A700: #a8c0ff,
    ),
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$dat-app-primary: mat-palette($dat-primary-palette);
$dat-app-accent: mat-palette($dat-accent-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$dat-app-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$dat-app-theme: mat-light-theme(
    $dat-app-primary,
    $dat-app-accent,
    $dat-app-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($dat-app-theme);

// Dark Theme

$dat-dark-theme-primary: mat-palette($dat-dark-primary-palette);
$dat-dark-theme-accent: mat-palette($dat-dark-accent-palette, A200, A100, A400);
$dat-app-dark-theme: mat-dark-theme(
    $dat-dark-theme-primary,
    $dat-dark-theme-accent,
    $dat-app-warn
);

.dat-dark-theme {
    @include angular-material-theme($dat-app-dark-theme);
}

$custom-typography: mat-typography-config(
    $font-family: $datiq-font-family,
);

@include mat-base-typography($custom-typography);
@include angular-material-typography($custom-typography);

// Material Overrides

.cdk-overlay-dark-backdrop {
    // background: rgba(black, 0.588);
}

.mat-dialog-title {
    margin: 0 0 16px 0;
    display: block;
}

.mat-dialog-content {
    color: $dark-secondary-text;
    line-height: 20px;
    font-size: 13px;
}

.mat-dialog-actions {
    align-content: flex-end;
    justify-content: flex-end;

    button {
        text-transform: uppercase;
        font-weight: 400;
    }
}

// disable menu item being truncated
.mat-menu-panel.menu-no-maxwidth {
    max-width: none !important;
}
//// styling the multi select in search form
.mat-mdc-tab-label-container {
    background: white;
    box-shadow: 0px 0 5px #888, 0px 0 5px #888;
}
.mat-mdc-tab-body-content {
    max-height: 400px;
}
.mat-select-panel {
    overflow: hidden !important;
    max-height: none !important;
}
.mat-tab-label {
    width: 50%;
}

.mat-button,
.mat-icon-button {
    &.cdk-program-focused {
        background-color: transparent !important;
        .mat-button-focus-overlay {
            display: none !important;
        }
    }
}

.mat-drawer-transition .mat-drawer-backdrop {
    &:not(.mat-drawer-shown) {
        transition-duration: 0s;
    }
    &.mat-drawer-shown {
        position: fixed;
        opacity: 0.3;
        background: #000000;
    }
}

.mat-error {
    div + div {
        display: none;
    }
}

$dat-grey: (
    050: #f6f7f9,
    100: #e9ecf1,
    200: #c7cfd6,
    300: #9ca5af,
    400: #7b8793,
    500: #636d79,
    600: #4e5a65,
    700: #3b4854,
    800: #2d3943,
    900: #192129,
    1000: #0a0d10,
);
$dat-pool: (
    100: #aff2ff,
    200: #74eafe,
    300: #00e1ff,
    400: #00dafd,
    500: #00c1e5,
    600: #00acc9,
    700: #0097af,
);
$dat-regal: (
    100: #e1bdf5,
    200: #ce91f0,
    300: #b962eb,
    400: #9900e0,
    500: #6d0ad3,
    600: #5000c5,
    700: #3600a3,
);

$dat-blue: (
    050: #e9effe,
    100: #c7d7fa,
    200: #9fbbf7,
    300: #5789f4,
    400: #1c60f2,
    500: #0046e0,
    600: #0041cd,
    700: #0032a8,
    800: #002480,
    900: #001a66,
);

$dat-success: (
    050: #e4ffde,
    100: #cdf8c3,
    200: #a9f39b,
    300: #7fed6e,
    400: #00e107,
    500: #00d000,
    600: #00ba00,
    700: #008002,
);

$dat-signal-red: (
    050: #fff4f3,
    100: #ffe6e5,
    200: #ff9c99,
    300: #ff6c66,
    400: #ff211a,
    500: #e10600,
    600: #ad0600,
    700: #7a0400,
);

$dat-signal-yellow: (
    100: #fff9c0,
    200: #fff595,
    300: #fef167,
    400: #fae700,
    500: #ffd700,
    600: #ffc000,
    700: #ffa700,
);
$dat-flame: (
    050: #fff3d4,
    100: #ffeab2,
    200: #ffdd81,
    300: #ffd14d,
    400: #ffbc04,
    500: #ffae00,
    600: #ff9b02,
    700: #fe6a07,
);

//#region DAT Defaults
$default-dat-grey: map-get(
    $map: $dat-grey,
    $key: 900,
);
$default-dat-pool: map-get(
    $map: $dat-pool,
    $key: 400,
);
$default-dat-regal: map-get(
    $map: $dat-regal,
    $key: 400,
);
$default-dat-dark: map-get(
    $map: $dat-grey,
    $key: 1000,
);
$default-dat-light: map-get(
    $map: $dat-grey,
    $key: 700,
);
$default-dat-success: map-get(
    $map: $dat-success,
    $key: 400,
);
$default-dat-error: map-get(
    $map: $dat-signal-red,
    $key: 500,
);
$default-dat-blue: map-get(
    $map: $dat-blue,
    $key: 500,
);
$default-dat-blue-light: map-get(
    $map: $dat-blue,
    $key: 200,
);
$default-dat-blue-dark: map-get(
    $map: $dat-blue,
    $key: 700,
);
$default-dat-signal-yellow: map-get(
    $map: $dat-signal-yellow,
    $key: 500,
);
$default-dat-flame: map-get(
    $map: $dat-flame,
    $key: 400,
);
$bg: #ffffff;
$surface: #ffffff;
$white: #ffffff;
$black: #000000;

$dat-color-default: (
    grey: $default-dat-grey,
    dark: $default-dat-dark,
    light: $default-dat-light,
    success: $default-dat-success,
    error: $default-dat-error,
    blue: $default-dat-blue,
    blueLight: $default-dat-blue-light,
    blueDark: $default-dat-blue-dark,
    yellow: $default-dat-signal-yellow,
    bg: $bg,
    surface: $surface,
    flame: $default-dat-flame,
    primary: $default-dat-blue,
    regal: $default-dat-regal,
    pool: $default-dat-pool,
    white: $white,
    black: $black,
    invert: $white,
);
//#endregion DAT Defaults

$dat-colors: (
    grey: $dat-grey,
    signal-red: $dat-signal-red,
    success: $dat-success,
    dat: $dat-color-default,
    pool: $dat-pool,
    blue: $dat-blue,
    regal: $dat-regal,
    yellow: $dat-signal-yellow,
    flame: $dat-flame,
);

$dat-color-opacity: (
    light: 0.9,
    default: 0.5,
    heavy: 0.1,
);

@function get-dat-color($color, $value: 500) {
    $color-map: map-get(
        $map: $dat-colors,
        $key: $color,
    );

    @return map-get($color-map, $value);
}

@function get-dat-opacity($opacity: default) {
    @return map-get($dat-color-opacity, $opacity);
}

@function get-dat-transparent-color($color, $value: 500, $opacity: default) {
    $raw-color: get-dat-color($color, $value);

    @return fade-out($raw-color, get-dat-opacity($opacity));
}

@mixin setColorsForSVG($color) {
    ::ng-deep {
        svg {
            path {
                &[stroke] {
                    stroke: $color;
                }
                &[fill] {
                    &:not([fill='none']) {
                        fill: $color;
                    }
                }
            }
            polygon {
                fill: $color;
            }
            line {
                stroke: $color;
            }
        }
    }
}

@mixin datiqProgressBarOverrides() {
    ::ng-deep .mat-progress-bar-fill::after {
        background-color: get-dat-color(dat, blue);
    }

    ::ng-deep .mat-progress-bar-buffer {
        background: get-dat-color(blue, 100);
    }
}

@mixin datiqBorderBottomLight() {
    border-bottom: 1px solid get-dat-color(grey, 100);
}

@mixin datiqBorder() {
    border: 1px solid get-dat-color(grey, 100);
    border-radius: 4px;
}

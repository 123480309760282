@import './dat-typography.scss';
@import './dat-colors.scss';

@mixin datiqTooltipPrimary() {
    @include datStrong();
    font-size: 12px;
    font-family: $datiq-font-family;

    @include setColorsForSVG(get-dat-color(dat, white));
}

@mixin datiqMatOverride() {
    .mat-mdc-tooltip {
        --mdc-plain-tooltip-container-color: #{get-dat-color(blue, 700)};
        --mdc-plain-tooltip-supporting-text-font: #{$datiq-font-family};
        --mdc-plain-tooltip-supporting-text-weight: #{$dat-font-weight-strong};
    }
    .mdc-tooltip {
        @include datiqTooltipPrimary;
        opacity: 0.9;
        &__surface {
            @include datiqTooltipPrimary;
            opacity: 0.9;
        }
    }
}

@mixin datiqTooltip() {
    @include datiqMatOverride();
}

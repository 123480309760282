@mixin datiqMat15UsurpDialogActionsOverride() {
    box-sizing: border-box;
    justify-content: flex-end;
    margin-bottom: 0;
    min-height: initial;
    padding: 0 24px 20px;
    width: 100%;
}

@mixin datiqMat15UsurpOverride() {
    .dat-preu-modal-wrapper {
        .mat-mdc-dialog-actions {
            @include datiqMat15UsurpDialogActionsOverride();

            .button-cancel {
                background: transparent;
                color: rgba(0, 145, 234, 0.87);
            }
        }
    }

    .dat-umodal-wrapper {
        .mat-mdc-dialog-actions {
            @include datiqMat15UsurpDialogActionsOverride();
        }
    }
}
@import './dat-typography.scss';
@import './dat-scrollbar.scss';
@import './dat-inputs.scss';
@import './dat-tooltip.scss';
@import './dat-autocomplete.scss';
@import './dat-menu.scss';
@import './dat-message.scss';

html {
    height: 100%;
    box-sizing: border-box;
}

@include datiqScrollbar();
@include datiqInputSelectPanel();
@include datiqTooltip();
@include datiqAutocomplete();
@include datiqInputDatepickerCalendar();
@include datiqMenuMaterialOverrides();
@include datiqMessage();
@include datiqInputBaSelectPanel();
*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    font-family: $datiq-font-family;
    margin: 0;
    line-height: 1.428;
    height: 100%;
}

.open-block-component-button {
    margin: 40px !important;
    background: white;
    text-transform: uppercase;
}

.mat-menu-item {
    padding-left: 24px;
    width: 380px;

    .mat-icon {
        margin-right: 24px;

        & + span {
            font-weight: 500;
            color: #000000;
            letter-spacing: 0.13px;
        }
    }
}

.user-information {
    &__section {
        .side-nav {
            width: 400px;

            &__logout,
            &__divider {
                display: none;
            }
        }
    }
}

.log-out {
    &__section {
        .side-nav {
            &__user-info,
            &__divider {
                display: none;
            }
        }
    }
}

.logo {
    &__section {
        height: 48px;
        padding: 0;
        background-color: #333333;
        color: #ffffff;
        overflow: hidden;

        .global-header__dat-logo {
            margin: 10px 20px;
            font-weight: normal;
            font-size: 20px;
        }
    }
}

.hamburger-menu__section {
    .mat-toolbar {
        h1,
        button:not(:first-child) {
            display: none;
        }
    }

    .global-header {
        background-color: #f1f1f1;
    }
}

.quick-access-icons__section {
    .global-header {
        .global-header__menu-toggler {
            display: none;
        }

        &__dat-logo {
            display: none;
        }
    }
}

.datiq-datepicker {
    .mat-date-range-input-mirror {
        @include datBodyFontStrong(

        ); // JTN this is necessary to prevent bold text from getting cut off in range picker
    }
}
.mat-option-text {
    @include datBodyFontRegular();
}

//panelClasses referenced in datiq-select

.select {
    margin-top: 56px !important; //NN: this is the only solution I could find to move the panel below the dropdown
}

/**
 * @george_pe -> This is to fix positioning the MC panel. We should figure out a better solution later.
 */
.datiq-mc-selection-bar-graphic__select__panel {
    margin-top: -40px !important;
}
//sets the pre tag font family for preserving the table cell whitespaces
pre {
    font-family: $datiq-font-family;
}
